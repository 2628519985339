import React, { useState } from 'react'
import Footer from '../Includes/Footer'
import { useFormik } from "formik";
import Header from '../Includes/Header'
import UserService from '../../services/user.service';
import { contactSchema } from '../../schema';

export default function Contact() {

    const [loading, setLoading] = useState(false);
    const [submitMessage, setSubmitMessage] = useState("");


    const formSubmit = async (values, action) => {
        setLoading(true);
        try {
            await UserService.contactSubmit(values);
            setSubmitMessage("Message sent");
            action.resetForm();
            setLoading(false);
        } catch (err) {
            setSubmitMessage("Error occurred");
            setLoading(false);
        }
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: {
                name: "",
                email: "",
                phone: "",
                message: "",
            },
            validationSchema: contactSchema,
            onSubmit: (values, action) => {
                console.log(values);
                formSubmit(values, action);
            },
        });

    return (
        <>
            <Header />
            <section>
                <div className="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div className="fixed-bg" style={{ backgroundImage: `url(assets/images/page-title-bg.jpg)` }}></div>
                    <div className="container">
                        <div className="page-title-wrap text-center w-100">
                            <div className="page-title-inner d-inline-block">
                                <h1 className="mb-0">Contact Us</h1>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="/" title="Home">Online Teaching Quran -
                                        Home</a></li>
                                    <li className="breadcrumb-item active">Contact Us</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="w-100 pt-90 thm-layer opc6 position-relative">
                    <div className="fixed-bg patern-bg back-blend-multiply thm-bg"
                        style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}></div>
                    <div className="container">
                        <div className="cont-info-wrap res-row overlap-115 w-100">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-lg-4">
                                    <div className="cont-info-box-wrap w-100">
                                        <div className="cont-info-box text-center position-relative w-100 pat-bg white-layer opc8 back-blend-multiply bg-white"
                                            style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}>
                                            <span className="bg-color1"><i className="flaticon-call"></i></span>
                                            <h4 className="mb-0">Phone Number</h4>
                                            <p className="mb-0">{localStorage.getItem("phone")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-4">
                                    <div className="cont-info-box-wrap w-100">
                                        <div className="cont-info-box text-center position-relative w-100 pat-bg white-layer opc8 back-blend-multiply bg-white"
                                            style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}>
                                            <span className="bg-color1"><i className="flaticon-mail"></i></span>
                                            <h4 className="mb-0">Email Address</h4>
                                            <p className="mb-0"><a href={`mailto:${localStorage.getItem("email")}`}
                                                title="">{localStorage.getItem("email")}</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-4">
                                    <div className="cont-info-box-wrap w-100">
                                        <div className="cont-info-box text-center position-relative w-100 pat-bg white-layer opc8 back-blend-multiply bg-white"
                                            style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}>
                                            <span className="bg-color1"><i className="fas fa-map-marker-alt"
                                                style={{ color: "#fff" }}></i></span>
                                            <h4 className="mb-0">Office Location</h4>
                                            <p className="mb-0">Islamabad, Pakistan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="w-100 pb-250 position-relative">
                    <div id="contact-map" className="contact-map w-100" style={{ height: "15.6875rem" }}></div>
                    <div className="container">
                        <div className="contact-wrap mt-0 w-100">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-lg-12">
                                    <h3 className="mb-5">Don't be a stranger just say hello.</h3>
                                    {submitMessage && (
                                        <div class="alert alert-info">
                                            {submitMessage}
                                        </div>
                                    )}
                                    <form onSubmit={handleSubmit} noValidate className="cont-form w-100">
                                        <input
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name='name'
                                            placeholder="Complete Name"
                                            value={values.name || ""} />
                                        {errors.name && touched.name ? (
                                            <p className="error">{errors.name}</p>
                                        ) : null}
                                        <input
                                            type="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name='email'
                                            placeholder="Email Address"
                                            value={values.email || ""} />
                                        {errors.email && touched.email ? (
                                            <p className="error">{errors.email}</p>
                                        ) : null}
                                        <input
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name='phone'
                                            placeholder="Phone No"
                                            value={values.phone || ""} />
                                        {errors.phone && touched.phone ? (
                                            <p className="error">{errors.phone}</p>
                                        ) : null}
                                        <textarea
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name='message'
                                            placeholder="Message"
                                            value={values.message || ""}></textarea>
                                        {errors.message && touched.message ? (
                                            <p className="error">{errors.message}</p>
                                        ) : null}
                                        <button
                                            className="thm-btn thm-bg"
                                            type="submit">SEND
                                            MESSAGE
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}