import React, { useLayoutEffect, useState } from 'react'
import Footer from '../Includes/Footer'
import Header from '../Includes/Header'
import UserService from '../../services/user.service';

export default function Home() {
    const date = new Date();

    let dayNo = date.getDay();
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    let fullDate = days[dayNo] + ", " + day + " " + monthNames[month] + ", " + year;

    const [resultData, setResultData] = useState([]);
    const [servicesData, setServicesData] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [hijri, setHijri] = useState("");
    const [starter, setStarter] = useState("");
    const [starterPrice, setStarterPrice] = useState("");
    const [starterDuration, setStarterDuration] = useState("");
    const [essential, setEssential] = useState("");
    const [essentialPrice, setEssentialPrice] = useState("");
    const [essentialDuration, setEssentialDuration] = useState("");
    const [business, setBusiness] = useState("");
    const [businessPrice, setBusinessPrice] = useState("");
    const [businessDuration, setBusinessDuration] = useState("");
    const [loading, setLoading] = useState(false);

    const getPricesData = async () => {
        setLoading(true);
        try {
            const response = await UserService.enrollments();
            console.log(response.data.response[0]);
            setStarter(response.data.response[0].starter);
            setStarterPrice(response.data.response[0].price);
            setStarterDuration(response.data.response[0].duration);
            setEssential(response.data.response[1].starter);
            setEssentialPrice(response.data.response[1].price);
            setEssentialDuration(response.data.response[1].duration);
            setBusiness(response.data.response[2].starter);
            setBusinessPrice(response.data.response[2].price);
            setBusinessDuration(response.data.response[2].duration);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const getResultData = async () => {
        setLoading(true);
        try {
            let resultData;
            const response = await UserService.namazTime();
            resultData = response.data.response;
            setResultData(resultData);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const getServices = async () => {
        setLoading(true);
        try {
            let resultData;
            const response = await UserService.services();
            resultData = response.data.response;
            setServicesData(resultData);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const getPrograms = async () => {
        setLoading(true);
        try {
            let resultData;
            const response = await UserService.programs();
            resultData = response.data.response;
            setPrograms(resultData);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const getDate = async () => {
        setLoading(true);
        try {
            let resultData;
            const response = await UserService.getDate(day, month+1, year);
            // console.log(response.data.data.hijri.month.en);
            resultData = response.data.data.hijri.month.en + ' ' + response.data.data.hijri.day + ', ' + response.data.data.hijri.year;
            setHijri(resultData);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useLayoutEffect(() => {
        getPricesData();
        getResultData();
        getServices();
        getPrograms();
        getDate();
    }, []);

    return (
        <>
            <Header />
            {/* Slider Banner */}
            <section>
                <div className="w-100 position-relative">
                    <div className="feat-wrap v2 position-relative w-100">
                        <div className="feat-caro">
                            <div className="feat-item v22">
                                <div className="feat-img position-absolute"
                                    style={{ backgroundImage: `url(assets/images/resources/slide2.jpg)` }}></div>
                                <div className="feat-cap-wrap position-absolute d-inline-block">
                                    <div className="feat-cap left-icon d-inline-block">
                                        <i className="d-inline-block flaticon-rub-el-hizb thm-clr"></i>
                                        <h2 className="mb-0">Quran Pak For All Person Of World</h2>
                                        <p className="mb-0">إِقْرَأْ بِاسْمِ رَبِّكَ الَّذِي خَلَقَ ﴿١﴾ خَلَقَ الْإِنسَانَ مِنْ
                                            عَلَقٍ</p>
                                    </div>
                                </div>
                            </div>
                            <div className="feat-item v22">
                                <div className="feat-img position-absolute"
                                    style={{ backgroundImage: `url(assets/images/resources/slide1.jpg)` }}></div>
                                <div className="feat-cap-wrap position-absolute d-inline-block">
                                    <div className="feat-cap left-icon d-inline-block">
                                        <i className="d-inline-block flaticon-rub-el-hizb thm-clr"></i>
                                        <h2 className="mb-0">Quran Pak For All Person Of World</h2>
                                        <p className="mb-0">إِقْرَأْ بِاسْمِ رَبِّكَ الَّذِي خَلَقَ ﴿١﴾ خَلَقَ الْإِنسَانَ مِنْ
                                            عَلَقٍ</p>

                                    </div>
                                </div>
                            </div>
                            <div className="feat-item v22">
                                <div className="feat-img position-absolute"
                                    style={{ backgroundImage: `url(assets/images/resources/slide3.jpg)` }}></div>
                                <div className="feat-cap-wrap position-absolute d-inline-block">
                                    <div className="feat-cap left-icon d-inline-block">
                                        <i className="d-inline-block flaticon-rub-el-hizb thm-clr"></i>
                                        <h2 className="mb-0">Quran Pak For All Person Of World</h2>
                                        <p className="mb-0">إِقْرَأْ بِاسْمِ رَبِّكَ الَّذِي خَلَقَ ﴿١﴾ خَلَقَ الْإِنسَانَ مِنْ
                                            عَلَقٍ</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="w-100 position-relative">
                    <div className="container">
                        <div className="plyr-wrp v2 overlap45 overlap-45 w-100">
                            <div className="row mrg">
                                <div className="col-md-12 col-sm-12 col-lg-4">
                                    <h3 className="mb-0 text-center pat-bg thm-layer opc65 back-blend-multiply thm-bg"
                                        style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}>Listen to Quran Audio
                                    </h3>
                                </div>
                                <div className="col-md-12 col-sm-12 col-lg-8">
                                    <div className="plyr-inner position-relative pat-bg white-layer opc9 back-blend-multiply bg-white"
                                        style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}>
                                        <div className="plyr v2 w-100">
                                            <ul className="playlist mb-0 list-unstyled">
                                                <li data-cover="assets/images/audio-cover.jpg" data-artist="(Abd-ur Rahman)"><a
                                                    href="assets/audio/surah-fateh.mp3" title="">Surah Fath</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="w-100 pt-220 pb-80 position-relative">
                    <img className="img-fluid sec-top-mckp position-absolute" src="assets/images/sec-top-mckp.png"
                        alt="Sec Top Mockup" />
                    <div className="container">
                        <div className="about-wrap text-center position-relative w-100">
                            <div className="about-inner d-inline-block" style={{ maxWidth: "100%" }}>
                                <img className="img-fluid" src="assets/images/bism-img1.png" alt="Bismillah Image" />
                                <h2 className="mb-0">Welcome To The Online Teaching Quran Pakistan
                                </h2>
                                <p className="mb-0">Online teaching Quran Pakistan offers modern teaching methods for all non-Arabic
                                    speakers Muslims who want to learn Arabic, & Quran Islamic studies. Here; you can learn
                                    Arabic, Tilawat e Quran, tafseer e Quran, Tajweed Quran, and Islamic studies from an
                                    Arabic-speaking Quran teacher who has graduated from the most popular Islamic universities
                                    in the world. Online Quran classes aim is every person to learn the Quran tilawat. If you
                                    are interested in learning Quran & more about the Quran & Islamic education. Online Quran
                                    teaching Pakistan provides yon Quran sharif ki tilawat, Tafseer Quran & Tajweed Quran
                                    courses for beginners & advanced to learn and read the tilawat Quran. If you want to know
                                    how to teach online Quran? So you can take free trial classes. Online Quran teaching has the
                                    greatest online Quran tutor for Quran & Islamic Studies you can join Quran Academy
                                    Islamabad. Quran teachers provide Hifz Quran and Arabic courses & you can learn the Arabic
                                    language, which is one of the most beauty full languages in the world. Wherever you want in
                                    the world with Online Quran classes. Online Quran teaching main goal is to bridge the
                                    information gap between the world This site was started to impart knowledge about the Holy
                                    Quran and teach Arabic to students who want to learn the Arabic language. Online Quran
                                    teaching gives them an opportunity to listen, learn, & understand the Quran. Quran Teachers
                                    teach you Online Quran reading with Urdu translation. Quran teaching online learns and
                                    understands the language and guides you through the Holy Quran. Online Quran teaching has
                                    the best team. Online Quran tutors teach you the best course structures, guides, tutorials,
                                    worksheets, and research. Quran teacher will help you best online Quran teaching for kids &
                                    families.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="sec-title text-center w-100">
                <div className="sec-title-inner d-inline-block">
                    <i className="thm-clr flaticon-rub-el-hizb"></i>
                    <h2 className="mb-0">What We Offer</h2>
                    <p className="mb-0 mt-0">Our Services</p>
                </div>
            </div>
            <section>
                <div className="w-100 pt-20 pb-50 position-relative">

                    <div className="container">
                        <div className="serv-wrap wide-sec">
                            <div className="row mrg10">

                                {servicesData.length && (
                                    servicesData.map((el, index) => (
                                        <div className="col-md-6 col-sm-6 col-lg-3">
                                            <div className="serv-box text-center pat-bg gray-layer opc8 position-relative back-blend-multiply gray-bg w-100"
                                                style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}>
                                                <i className="flaticon-quran thm-clr"></i>
                                                <h3 className="mb-0">{el.name}</h3>
                                                <p className="mb-0 mt-0">{el.name}</p>
                                                {/* <a href="services-detail.html" title="">Read More</a> */}
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="w-100 pt-100 gray-layer opc8 position-relative">
                    <div className="fixed-bg" style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}></div>
                    <div className="container">
                        <div className="sec-title text-center w-100">
                            <div className="sec-title-inner d-inline-block">
                                <i className="thm-clr flaticon-rub-el-hizb"></i>
                                <h2 className="mb-0">Enrollment Is Ongoing</h2>
                                <p className="mb-0">className TIMINGS: MONDAY - THURSDAY 12:00 PM TO 1:30 PM</p>
                            </div>
                        </div>
                        <div className="pack-wrap overlap-115 w-100">
                            <div className="pack-inner res-row position-relative w-100">
                                <div className="row mrg">
                                    <div className="col-md-6 col-sm-6 col-lg-4">
                                        <div className="pack-box w-100">
                                            <div className="pack-head w-100">
                                                <h3 className="mb-0">Starters</h3>
                                                <p className="mb-0">For 9 to 12 Years Childerns</p>
                                                <span className="thm-clr"><sup>{starter}</sup>{starterPrice}<small>/ {starterDuration}</small></span>
                                            </div>
                                            
                                            <div className="pack-body w-100">
                                                <ul className="mb-0 list-unstyled w-100">
                                                    <li>Taleemul Qur'an</li>
                                                    <li>Names of Allah</li>
                                                    <li>Excerpts from the syllabus</li>
                                                    <li>Islamic History</li>
                                                    <li>How to Perform Namaz</li>
                                                </ul>
                                                <a className="thm-btn thm-bg" href="javascript:void(0);" title="">Get
                                                    Started<span></span><span></span><span></span><span></span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-4">
                                        <div className="pack-box active w-100">
                                            <div className="pack-head w-100">
                                                <h3 className="mb-0">Essentials</h3>
                                                <p className="mb-0">For Age Group 13 yrs - 16 yrs</p>
                                                <span className="thm-clr"><sup>{essential}</sup>{essentialPrice}<small>/ {essentialDuration}</small></span>
                                            </div>
                                            <div className="pack-body w-100">
                                                <ul className="mb-0 list-unstyled w-100">
                                                    <li>Qur'an Reading with Tajweed</li>
                                                    <li>Islamic Books</li>
                                                    <li>Islamic Manners</li>
                                                    <li>Islamic History</li>
                                                    <li>Online Quran</li>
                                                </ul>
                                                <a className="thm-btn thm-bg" href="javascript:void(0);" title="">Get
                                                    Started<span></span><span></span><span></span><span></span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-4">
                                        <div className="pack-box w-100">
                                            <div className="pack-head w-100">
                                                <h3 className="mb-0">Business</h3>
                                                <p className="mb-0">Age Group 17 + yrs</p>
                                                <span className="thm-clr"><sup>{business}</sup>{businessPrice}<small>/ {businessDuration}</small></span>
                                            </div>
                                            <div className="pack-body w-100">
                                                <ul className="mb-0 list-unstyled w-100">
                                                    <li>Taleemul Qur'an</li>
                                                    <li>Names of Allah</li>
                                                    <li>Excerpts from the syllabus</li>
                                                    <li>Islamic History</li>
                                                    <li>How to Perform Namaz</li>
                                                </ul>
                                                <a className="thm-btn thm-bg" href="javascript:void(0);" title="">Get
                                                    Started<span></span><span></span><span></span><span></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="w-100 position-relative " style={{ marginTop: "200px" }}>
                    <div className="time-course-wrap w-100">
                        <div className="row mrg">
                            <div className="col-md-12 col-sm-12 col-lg-6">
                                <div
                                    className="time-wrap d-flex flex-wrap align-items-center justify-content-end thm-layer opc95 position-relative w-100">
                                    <div className="fixed-bg" style={{ backgroundImage: `url(assets/images/time-bg.jpg)` }}></div>
                                    <div className="time-inner w-100">
                                        <div className="sec-title w-100">
                                            <div className="sec-title-inner d-inline-block">
                                                <h2 className="mb-0">Go to Allah Before its to Late</h2>
                                                <p className="mb-0">Islamic:, {hijri} AH - {fullDate}</p>
                                            </div>
                                        </div>
                                        <div className="time-list-wrap d-flex flex-wrap w-100">
                                            <ul className="time-list mb-0 list-unstyled">
                                                {resultData.length && (
                                                    resultData.map((el, index) => (
                                                        index < 3 && (
                                                            <li>
                                                                <div
                                                                    className="time-box d-flex flex-wrap align-items-center justify-content-between">
                                                                    <span><i className="flaticon-rub-el-hizb"></i>{el.name}</span>
                                                                    <span>{el.time}</span>
                                                                </div>
                                                            </li>
                                                        )
                                                    ))
                                                )}
                                            </ul>
                                            <ul className="time-list mb-0 list-unstyled">
                                                {resultData.length && (
                                                    resultData.map((el, index) => (
                                                        index > 2 && (
                                                            <li>
                                                                <div
                                                                    className="time-box d-flex flex-wrap align-items-center justify-content-between">
                                                                    <span><i className="flaticon-rub-el-hizb"></i>{el.name}</span>
                                                                    <span>{el.time}</span>
                                                                </div>
                                                            </li>
                                                        )
                                                    ))
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-6">
                                <div
                                    className="course-wrap d-flex flex-wrap align-items-center black-layer opc7 position-relative w-100">
                                    <div className="fixed-bg" style={{ backgroundImage: `url(assets/images/course-bg.jpg)` }}></div>
                                    <div className="course-inner w-100">
                                        <div className="sec-title w-100">
                                            <div className="sec-title-inner d-inline-block">
                                                <h2 className="mb-0">Islamic School Courses</h2>
                                                <p className="mb-0">Adipiscing elit duis volutpat ligula nulla dapibus.</p>
                                            </div>
                                        </div>
                                        <div className="course-list-wrap w-100">
                                            <h3 className="mb-0 thm-clr">Weekly Programs</h3>
                                            <ul className="course-list mb-0 list-unstyled w-100">
                                            {programs.length && (
                                            programs.map((el, index) => (                                                
                                                <li>
                                                    <div className="course-box d-flex flex-wrap w-100">
                                                        <i className="flaticon-rub-el-hizb thm-clr"></i>
                                                        <div className="course-inner">
                                                            <h4 className="mb-0"><a href="online-courses-detail.html"
                                                                title="">{el.name}</a></h4>
                                                            <p className="mb-0">{el.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                ))
                                                )}
                                                {/* <li>
                                                    <div className="course-box d-flex flex-wrap w-100">
                                                        <i className="flaticon-rub-el-hizb thm-clr"></i>
                                                        <div className="course-inner">
                                                            <h4 className="mb-0"><a href="online-courses-detail.html"
                                                                title="">Children's Islamic Classes</a></h4>
                                                            <p className="mb-0">Daily from 8:00 am to 1:00 pm</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="course-box d-flex flex-wrap w-100">
                                                        <i className="flaticon-rub-el-hizb thm-clr"></i>
                                                        <div className="course-inner">
                                                            <h4 className="mb-0"><a href="online-courses-detail.html" title="">Quran
                                                                Recitation</a></h4>
                                                            <p className="mb-0">Every Monday &amp; Thursday 9:00 am to 11:30 pm</p>
                                                        </div>
                                                    </div>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ marginTop: "100px" }}>
                <div className="w-100 pt-110 black-layer pb-70 opc7 position-relative">
                    <div className="fixed-bg" style={{ backgroundimage: `url(assets/images/course-bg.jpg)` }}></div>
                    <div className="container">
                        <div className="sec-title v2 text-center w-100">
                            <div className="sec-title-inner d-inline-block">
                                <i className="flaticon-rub-el-hizb thm-clr"></i>
                                <h1 className="mb-0">The Pillars of Islam</h1>
                                <p className="mb-0">Duis aute irure dolor in reprehenit in voluptate velit esse cillum dolore eu
                                    fugiat nulla pariatur.</p>
                            </div>
                        </div>
                        <div className="pillars-wrap w-100">
                            <ul
                                className="pillars-list text-center d-flex flex-wrap justify-content-center align-items-center mb-0 list-unstyled">
                                <li>
                                    <div className="pillar-box position-relative w-100">
                                        <span className="d-inline-block thm-clr position-relative"><span
                                            className="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg"
                                            style={{ backgroundimage: `url(assets/images/pattern-bg.jpg)` }}></span><i
                                                className="flaticon-quran-1"></i></span>
                                        <h3 className="mb-0">Shahadah</h3>
                                        <i className="d-block thm-clr">Faith</i>
                                    </div>
                                </li>
                                <li>
                                    <div className="pillar-box position-relative w-100">
                                        <span className="d-inline-block thm-clr position-relative"><span
                                            className="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg"
                                            style={{ backgroundimage: `url(assets/images/pattern-bg.jpg)` }}></span><i
                                                className="flaticon-mosque-1"></i></span>
                                        <h3 className="mb-0">Salah</h3>
                                        <i className="d-block thm-clr">Prayer</i>
                                    </div>
                                </li>
                                <li>
                                    <div className="pillar-box position-relative w-100">
                                        <span className="d-inline-block thm-clr position-relative"><span
                                            className="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg"
                                            style={{ backgroundimage: `url(assets/images/pattern-bg.jpg)` }}></span><i
                                                className="flaticon-star"></i></span>
                                        <h3 className="mb-0">Sawm</h3>
                                        <i className="d-block thm-clr">Fasting</i>
                                    </div>
                                </li>
                                <li>
                                    <div className="pillar-box position-relative w-100">
                                        <span className="d-inline-block thm-clr position-relative"><span
                                            className="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg"
                                            style={{ backgroundimage: `url(assets/images/pattern-bg.jpg)` }}></span><i
                                                className="flaticon-gift-box"></i></span>
                                        <h3 className="mb-0">Zakat</h3>
                                        <i className="d-block thm-clr">Almsgiving</i>
                                    </div>
                                </li>
                                <li>
                                    <div className="pillar-box position-relative w-100">
                                        <span className="d-inline-block thm-clr position-relative"><span
                                            className="pat-bg gray-layer opc85 position-absolute back-blend-multiply gray-bg"
                                            style={{ backgroundimage: `url(assets/images/pattern-bg.jpg)` }}></span><i
                                                className="flaticon-kaaba"></i></span>
                                        <h3 className="mb-0">Hajj</h3>
                                        <i className="d-block thm-clr">Pilgrimage</i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}