import * as Yup from "yup";

export const contactSchema = Yup.object({
    name: Yup.string()
        .required("Please enter Full Name"),
    email: Yup.string().email()
        .required("Please enter Email Address"),
    phone: Yup.string()
        .required("Please enter Phone No."),
    message: Yup.string()
        .required("Please enter Message"),
});