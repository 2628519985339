// import logo from './logo.svg';
import { Route, Routes } from 'react-router';
import './App.css';
import Home from './Component/Structure/Home';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import About from './Component/Structure/About';
import { BrowserRouter } from 'react-router-dom';
import Contact from './Component/Structure/Contact';
import ListenAudio from './Component/ListenAudio';

function App() {
  return (
    <>
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/audio-listening" element={<ListenAudio />} />
      </Routes>
      </BrowserRouter>
    </div>
    <FloatingWhatsApp
            phoneNumber="+923131775689"
            accountName="Online Teaching Quran"
            // chatMessage="Assalam o Alaikum, Main apki kya madad kr skta hu?"
            chatMessage="Assalam-u-Alikum! 
            We are here to help you. Send us a message and we'll get back to you as soon as possible."
            statusMessage=""
            placeholder="Type a message"
            avatar="/favicon.png"
            allowEsc
            allowClickAway
            notification
            notificationSound
    />
    </>
  );
}

export default App;
