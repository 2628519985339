import React, { useState } from 'react'
import UserService from '../../services/user.service';
import { useLayoutEffect } from 'react';

export default function Header() {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        sunrise: "",
        sunset: "",
        email: "",
        phone: "",
    });
    const getResultData = async () => {
        setLoading(true);
        try {
            let resultData;
            let resultData1;
            const response = await UserService.siteInfo();
            const response1 = await UserService.sunTime();
            resultData = response.data.response;
            resultData1 = response1.data.response;
            info.sunrise = resultData1[0].time;
            info.sunset = resultData1[1].time;
            info.email = resultData[0].email;
            info.phone = resultData[0].phone;
            localStorage.setItem("sunrise", info.sunrise);
            localStorage.setItem("sunset", info.sunset);
            localStorage.setItem("email", info.email);
            localStorage.setItem("phone", info.phone);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useLayoutEffect(() => {
        getResultData();
    }, []);


    return (
        <>
            <header className="stick style2 w-100">
                <div className="topbar bg-color1 d-flex flex-wrap justify-content-between w-100">
                    <div className="topbar-left">
                        <ul className="topbar-info-list mb-0 list-unstyled d-inline-flex">
                            <li><i className="thm-clr far fa-envelope"></i>
                            <a href={`mailto:${localStorage.getItem("email")}`}
                                title="">{localStorage.getItem("email")}</a>
                                </li>
                            <li><i className="thm-clr fas fa-phone-alt"></i>
                            <a href={`tel:${localStorage.getItem("phone")}`}>{localStorage.getItem("phone")}</a>
                            </li>
                        </ul>
                    </div>
                    <div className="topbar-right d-inline-flex">
                        <ul className="topbar-info-list mb-0 list-unstyled d-inline-flex">
                            <li><i className="thm-clr flaticon-sun"></i>Sunrise At: <span className="thm-clr">{localStorage.getItem("sunrise")}</span></li>
                            <li><i className="thm-clr flaticon-moon"></i>Sunset At: <span className="thm-clr">{localStorage.getItem("sunset")}</span></li>
                        </ul>
                        <div className="social-links d-inline-flex"></div>
                    </div>
                    {/* <div className="social-links d-inline-flex">
                    <a href="https://twitter.com/" title="Twtiiter" target="_blank"><i className="fab fa-twitter"></i></a>
                    <a href="https://www.facebook.com/" title="Facebook" target="_blank"><i
                            className="fab fa-facebook-f"></i></a>
                    <a href="https://www.youtube.com/" title="Youtube" target="_blank"><i
                            className="fab fa-youtube"></i></a>
                    <a href="https://www.linkedin.com/" title="Linkedin" target="_blank"><i
                            className="fab fa-linkedin-in"></i></a>
                    <a href="https://www.instagram.com/" title="Instagram" target="_blank"><i
                            className="fab fa-instagram"></i></a>
                </div> */}
                </div>
                <div className="logo-menu-wrap v2 d-flex flex-wrap align-items-center justify-content-between w-100 pat-bg thm-layer opc65 back-blend-multiply thm-bg"
                    style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}>
                    <div className="logo">
                        <h1 className="mb-0"><a href="/" title="Home"><img className="img-fluid" src="assets/images/logo3.png"
                            alt="Logo" srcset="assets/images/retina-logo3.png" /></a></h1>
                    </div>
                    <nav className="d-flex flex-wrap align-items-center justify-content-between">
                        <div className="header-left">
                            <ul className="mb-0 list-unstyled d-inline-flex">
                                <li><a href="/" title="">Home</a></li>
                                <li><a href="/audio-listening" title="">Audio-listening</a></li>
                                <li><a href="/about" title="">About Us</a></li>
                                <li><a href="/contact" title="">Contact Us</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            <div className="sticky-menu">
                <div className="container">
                    <div className="sticky-menu-inner d-flex flex-wrap align-items-center justify-content-between w-100">
                        <div className="logo">
                            <h1 className="mb-0"><a href="/" title="Home"><img className="img-fluid"
                                src="assets/images/logo3.png" alt="Logo" srcset="assets/images/logo3.png" /></a>
                            </h1>
                        </div>
                        <nav className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="header-left">
                                <ul className="mb-0 list-unstyled d-inline-flex">
                                    <li><a href="/" title="">Home</a></li>
                                    <li><a href="/audio-listening" title="">Audio-listening</a></li>
                                    <li><a href="/about" title="">About Us</a></li>
                                    <li><a href="/contact" title="">Contact Us</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="rspn-hdr">
                <div className="rspn-mdbr">
                    <div className="rspn-scil">
                        <a href="https://twitter.com/" title="Twtiiter" target="_blank"><i className="fab fa-twitter"></i></a>
                        <a href="https://www.facebook.com/" title="Facebook" target="_blank"><i
                            className="fab fa-facebook-f"></i></a>
                        <a href="https://www.youtube.com/" title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                        <a href="https://www.linkedin.com/" title="Linkedin" target="_blank"><i
                            className="fab fa-linkedin-in"></i></a>
                    </div>
                    <form className="rspn-srch">
                        <input type="text" placeholder="Enter Your Keyword" />
                        <button type="submit"><i className="fa fa-search"></i></button>
                    </form>
                </div>

                <div className="lg-mn">
                    <div className="logo"><a href="/" title="Home"><img src="assets/images/logo3.png" alt="Logo" /></a>
                    </div>
                    <div className="rspn-cnt">
                        <span><i className="thm-clr far fa-envelope"></i>
                        <a href="javascript:void(0);"
                            title="">{localStorage.getItem("sunrise")}</a>
                            </span>
                        <span><i className="thm-clr fas fa-phone-alt"></i>{localStorage.getItem("sunrise")}</span>
                    </div>
                    <span className="rspn-mnu-btn"><i className="fa fa-list-ul"></i></span>
                </div>
                <div className="rsnp-mnu">
                    <span className="rspn-mnu-cls"><i className="fa fa-times"></i></span>
                    <ul className="mb-0 list-unstyled w-100">
                        <li><a href="/" title="">Home</a></li>
                        <li><a href="/audio-listening" title="">Audio-listening</a></li>
                        <li><a href="/about" title="">About Us</a></li>
                        <li><a href="/contact" title="">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </>
    )
}