import React, { useLayoutEffect, useState } from 'react'
import Footer from '../Includes/Footer'
import Header from '../Includes/Header'
import UserService from '../../services/user.service';

export default function About() {
    const Image_URL = process.env.REACT_APP_Image_Link;

    const [resultData, setResultData] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [servicesData, setServicesData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getServices = async () => {
        setLoading(true);
        try {
            let resultData;
            const response = await UserService.services();
            resultData = response.data.response;
            setServicesData(resultData);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const getTeachers = async () => {
        setLoading(true);
        try {
            let resultData;
            const response = await UserService.teachers();
            resultData = response.data.response;
            setTeachers(resultData);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useLayoutEffect(() => {
        getServices();
        getTeachers();
    }, []);
return (
<>
    <Header />

    <section>
        <div className="w-100 pt-80 black-layer pb-70 opc65 position-relative">
            <div className="fixed-bg" style={{backgroundImage: `url(assets/images/page-title-bg.jpg)`}}></div>
            <div className="container">
                <div className="page-title-wrap text-center w-100">
                    <div className="page-title-inner d-inline-block">
                        <h1 className="mb-0">About</h1>
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="/" title="Home">Online Teaching Quran - Home</a>
                            </li>
                            <li className="breadcrumb-item active">About</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div className="w-100 pt-210 pb-80 position-relative">
            <img className="img-fluid sec-top-mckp position-absolute" src="assets/images/sec-top-mckp.png"
                alt="Sec Top Mockup" />
            <div className="container">
                <div className="about-wrap text-center position-relative w-100">
                    <div className="about-inner d-inline-block" style={{maxWidth: "100%"}}>
                        <img className="img-fluid" src="assets/images/bism-img1.png" alt="Bismillah Image" />
                        <h2 className="mb-0">Welcome To The Islam Home

                        </h2>
                        <p className="mb-0">The is not just a mosque for prayers rather it is a community center for
                            all. The Center is
                            committed to preserving an Islamic identity, building and supporting a viable Muslim
                            community, promoting
                            a comprehensive Islamic way of life based on the Holy Quran and the Sunnah of Prophet
                            Muhammad.

                        </p>

                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
                <div className="w-100 position-relative">
            
                    <div className="container">
                        <div className="sec-title v2 text-center w-100">
                            <div className="sec-title-inner d-inline-block">
                                <i className="flaticon-rub-el-hizb thm-clr"></i>
                                <h1 className="mb-0">Teachers</h1>
                            </div>
                        </div>
                        <div className="team-wrap wide-sec2">
                            <div className="row">
                            {teachers.length && (
                                    teachers.map((el, index) => (
                                <div className="col-md-6 col-sm-6 col-lg-3">
                                    <div className="team-box text-center w-100">
                                        <div className="team-img overflow-hidden position-relative w-100">
                                            <img className="img-fluid w-100" src={`${Image_URL}${el.image}`} alt="Team Image 1"/>
                                            <div className="social-links2 text-center d-inline-flex position-absolute">
                                                <a className="facebook" href={el.fb} title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="twitter" href={el.x} title="Twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                                                <a className="youtube" href={el.youtube} title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h3 className="mb-0"><a href="scholar-detail.html" title="">{el.name}</a></h3>
                                            <span className="d-block thm-clr">{el.designation}</span>
                                        </div>
                                    </div>
                                </div>
                                ))
                                )}
                            </div>
                        </div>
                        
                     
                    </div>
                </div>
            </section>


    {/* <section>
        <div className="w-100 pb-110 position-relative">
            <div className="container">
                <div className="sec-title text-center w-100">
                    <div className="sec-title-inner d-inline-block">
                        <i className="thm-clr flaticon-rub-el-hizb"></i>
                        <h2 className="mb-0">Services We Offer</h2>
                        <p className="mb-0">Adipiscing elit duis volutpat ligula nulla dapibus.</p>
                    </div>
                </div>
                <div className="serv-wrap wide-sec">
                    <div className="row mrg10 serv-caro">
                        <div className="col-md-4 col-sm-6 col-lg-3">
                            <div className="serv-box text-center pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100"
                                style={{backgroundImage: `url(assets/images/pattern-bg.jpg)`}}>
                                <i className="flaticon-mat thm-clr"></i>
                                <h3 className="mb-0">Help Poor's</h3>
                                <p className="mb-0">Lorem ipsum dolor sit amet, coteudtu adipisicing elit, sed do
                                    eiusmod tem por incididunt ut labore et.</p>
                                <a href="services-detail.html" title="">Read More</a>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-lg-3">
                            <div className="serv-box text-center pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100"
                                style={{backgroundImage: `url(assets/images/pattern-bg.jpg)`}}>
                                <i className="flaticon-minaret thm-clr"></i>
                                <h3 className="mb-0">Mosque Development</h3>
                                <p className="mb-0">Lorem ipsum dolor sit amet, coteudtu adipisicing elit, sed do
                                    eiusmod tem por incididunt ut labore et.</p>
                                <a href="services-detail.html" title="">Read More</a>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-lg-3">
                            <div className="serv-box text-center pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100"
                                style={{backgroundImage: `url(assets/images/pattern-bg.jpg)`}}>
                                <i className="flaticon-grave thm-clr"></i>
                                <h3 className="mb-0">Funeral Service</h3>
                                <p className="mb-0">Lorem ipsum dolor sit amet, coteudtu adipisicing elit, sed do
                                    eiusmod tem por incididunt ut labore et.</p>
                                <a href="services-detail.html" title="">Read More</a>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-lg-3">
                            <div className="serv-box text-center pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100"
                                style={{backgroundImage: `url(assets/images/pattern-bg.jpg)`}}>
                                <i className="flaticon-quran thm-clr"></i>
                                <h3 className="mb-0">Quran Learning</h3>
                                <p className="mb-0">Lorem ipsum dolor sit amet, coteudtu adipisicing elit, sed do
                                    eiusmod tem por incididunt ut labore et.</p>
                                <a href="services-detail.html" title="">Read More</a>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-lg-3">
                            <div className="serv-box text-center pat-bg gray-layer opc85 position-relative back-blend-multiply gray-bg w-100"
                                style={{backgroundImage: `url(assets/images/pattern-bg.jpg)`}}>
                                <i className="flaticon-arabic thm-clr"></i>
                                <h3 className="mb-0">Community Service</h3>
                                <p className="mb-0">Lorem ipsum dolor sit amet, coteudtu adipisicing elit, sed do
                                    eiusmod tem por incididunt ut labore et.</p>
                                <a href="services-detail.html" title="">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

<section>
                <div className="w-100 position-relative">
            
                    <div className="container">
                        <div className="sec-title v2 text-center w-100">
                            <div className="sec-title-inner d-inline-block">
                                <i className="flaticon-rub-el-hizb thm-clr"></i>
                                <h1 className="mb-0">Services We Offer</h1>
                            </div>
                        </div>
                        {/* <div className="team-wrap wide-sec2">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-lg-3">
                                    <div className="team-box text-center w-100">
                                        <div className="team-img overflow-hidden position-relative w-100">
                                            <img className="img-fluid w-100" src="assets/images/resources/team-img1-1.jpg" alt="Team Image 1"/>
                                            <div className="social-links2 text-center d-inline-flex position-absolute">
                                                <a className="facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="twitter" href="https://twitter.com/" title="Twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                                                <a className="youtube" href="https://www.youtube.com/" title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h3 className="mb-0"><a href="scholar-detail.html" title="">M. Ishaq</a></h3>
                                            <span className="d-block thm-clr">Scholar</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-3">
                                    <div className="team-box text-center w-100">
                                        <div className="team-img overflow-hidden position-relative w-100">
                                            <img className="img-fluid w-100" src="assets/images/resources/team-img1-2.jpg" alt="Team Image 2"/>
                                            <div className="social-links2 text-center d-inline-flex position-absolute">
                                                <a className="facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="twitter" href="https://twitter.com/" title="Twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                                                <a className="youtube" href="https://www.youtube.com/" title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h3 className="mb-0"><a href="scholar-detail.html" title="">Dorri-Najafabadi</a></h3>
                                            <span className="d-block thm-clr">Scholar</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-3">
                                    <div className="team-box text-center w-100">
                                        <div className="team-img overflow-hidden position-relative w-100">
                                            <img className="img-fluid w-100" src="assets/images/resources/team-img1-3.jpg" alt="Team Image 3"/>
                                            <div className="social-links2 text-center d-inline-flex position-absolute">
                                                <a className="facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="twitter" href="https://twitter.com/" title="Twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                                                <a className="youtube" href="https://www.youtube.com/" title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h3 className="mb-0"><a href="scholar-detail.html" title="">M. Ebrahim</a></h3>
                                            <span className="d-block thm-clr">Mufti</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-3">
                                    <div className="team-box text-center w-100">
                                        <div className="team-img overflow-hidden position-relative w-100">
                                            <img className="img-fluid w-100" src="assets/images/resources/team-img1-4.jpg" alt="Team Image 4"/>
                                            <div className="social-links2 text-center d-inline-flex position-absolute">
                                                <a className="facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="twitter" href="https://twitter.com/" title="Twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                                                <a className="youtube" href="https://www.youtube.com/" title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h3 className="mb-0"><a href="scholar-detail.html" title="">Reza Hosseini</a></h3>
                                            <span className="d-block thm-clr">Scholar</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-3">
                                    <div className="team-box text-center w-100">
                                        <div className="team-img overflow-hidden position-relative w-100">
                                            <img className="img-fluid w-100" src="assets/images/resources/team-img1-5.jpg" alt="Team Image 5"/>
                                            <div className="social-links2 text-center d-inline-flex position-absolute">
                                                <a className="facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="twitter" href="https://twitter.com/" title="Twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                                                <a className="youtube" href="https://www.youtube.com/" title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h3 className="mb-0"><a href="scholar-detail.html" title="">Hamad Bin Jasim</a></h3>
                                            <span className="d-block thm-clr">Scholar</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-3">
                                    <div className="team-box text-center w-100">
                                        <div className="team-img overflow-hidden position-relative w-100">
                                            <img className="img-fluid w-100" src="assets/images/resources/team-img1-6.jpg" alt="Team Image 6"/>
                                            <div className="social-links2 text-center d-inline-flex position-absolute">
                                                <a className="facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="twitter" href="https://twitter.com/" title="Twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                                                <a className="youtube" href="https://www.youtube.com/" title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h3 className="mb-0"><a href="scholar-detail.html" title="">Ashraf Al-Maktum</a></h3>
                                            <span className="d-block thm-clr">Scholar</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-3">
                                    <div className="team-box text-center w-100">
                                        <div className="team-img overflow-hidden position-relative w-100">
                                            <img className="img-fluid w-100" src="assets/images/resources/team-img1-7.jpg" alt="Team Image 7"/>
                                            <div className="social-links2 text-center d-inline-flex position-absolute">
                                                <a className="facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="twitter" href="https://twitter.com/" title="Twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                                                <a className="youtube" href="https://www.youtube.com/" title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h3 className="mb-0"><a href="scholar-detail.html" title="">Saira Bano</a></h3>
                                            <span className="d-block thm-clr">Mufti</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-lg-3">
                                    <div className="team-box text-center w-100">
                                        <div className="team-img overflow-hidden position-relative w-100">
                                            <img className="img-fluid w-100" src="assets/images/resources/team-img1-8.jpg" alt="Team Image 8"/>
                                            <div className="social-links2 text-center d-inline-flex position-absolute">
                                                <a className="facebook" href="https://www.facebook.com/" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="twitter" href="https://twitter.com/" title="Twitter" target="_blank"><i className="fab fa-twitter"></i></a>
                                                <a className="youtube" href="https://www.youtube.com/" title="Youtube" target="_blank"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <h3 className="mb-0"><a href="scholar-detail.html" title="">Sayyida Hijaazi</a></h3>
                                            <span className="d-block thm-clr">Scholar</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

<section>
                <div className="w-100 pt-20 pb-50 position-relative">

                    <div className="container">
                        <div className="serv-wrap wide-sec">
                            <div className="row mrg10">

                                {servicesData.length && (
                                    servicesData.map((el, index) => (
                                        <div className="col-md-6 col-sm-6 col-lg-3">
                                            <div className="serv-box text-center pat-bg gray-layer opc8 position-relative back-blend-multiply gray-bg w-100"
                                                style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}>
                                                <i className="flaticon-quran thm-clr"></i>
                                                <h3 className="mb-0">{el.name}</h3>
                                                <p className="mb-0 mt-0">{el.name}</p>
                                                {/* <a href="services-detail.html" title="">Read More</a> */}
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                     
                    </div>
                </div>
            </section>


    <Footer />
</>
)
}