import React from "react";
import Header from "./Includes/Header";
import Footer from "./Includes/Footer";

export default function ListenAudio() {
  return (
    <>
      <Header />
      <section>
        <div className="w-100 pt-80 black-layer pb-70 opc65 position-relative">
          <div
            className="fixed-bg"
            style={{ backgroundImage: `url(assets/images/page-title-bg.jpg)` }}
          ></div>
          <div className="container">
            <div className="page-title-wrap text-center w-100">
              <div className="page-title-inner d-inline-block">
                <h1 className="mb-0">Audio Listening</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="/" title="Home">
                      Online Teaching Quran - Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Audio Listening</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="w-100 pt-80 pb-80 position-relative">
          <img
            className="img-fluid sec-top-mckp position-absolute"
            src="assets/images/sec-top-mckp.png"
            alt="Sec Top Mockup"
          />
          <div className="container">
            <div className="about-wrap text-center position-relative w-100">
              <div
                className="about-inner d-inline-block"
                style={{ maxWidth: "100%" }}
              >
                <img
                  className="img-fluid"
                  src="assets/images/bism-img1.png"
                  alt="Bismillah Image"
                />
                <h2 className="mb-0">
                  LISTEN TO QURAN AUDIO
                  <div className="plyr-wrp v2 overlap45 gray-layer overlap-45 w-100 audio-listen">
                    <div className="row mrg">
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <div
                          className="plyr-inner position-relative pat-bg white-layer opc9 back-blend-multiply bg-white"
                          style={{
                            backgroundImage: `url(assets/images/pattern-bg.jpg)`,
                          }}
                        >
                          <div className="plyr v2 w-100">
                            <ul className="playlist mb-0 list-unstyled">
                              <li
                                data-cover="assets/images/audio-cover1.jpg"
                                data-artist=""
                              >
                                <a href="assets/audio/surah-fateh.mp3" title="">
                                Al-Baqara
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plyr-wrp v2 overlap45 gray-layer overlap-45 w-100 audio-listen">
                    <div className="row mrg">
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <div
                          className="plyr-inner position-relative pat-bg white-layer opc9 back-blend-multiply bg-white"
                          style={{
                            backgroundImage: `url(assets/images/pattern-bg.jpg)`,
                          }}
                        >
                          <div className="plyr v2 w-100">
                            <ul className="playlist mb-0 list-unstyled">
                              <li
                                data-cover="assets/images/audio-cover2.jpg"
                                data-artist=""
                              >
                                <a href="assets/audio/surah-fateh.mp3" title="">
                                Al-Fatihah
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plyr-wrp v2 overlap45 gray-layer overlap-45 w-100 audio-listen">
                    <div className="row mrg">
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <div
                          className="plyr-inner position-relative pat-bg white-layer opc9 back-blend-multiply bg-white"
                          style={{
                            backgroundImage: `url(assets/images/pattern-bg.jpg)`,
                          }}
                        >
                          <div className="plyr v2 w-100">
                            <ul className="playlist mb-0 list-unstyled">
                              <li
                                data-cover="assets/images/audio-cover3.jpg"
                                data-artist=""
                              >
                                <a href="assets/audio/az-zumar.mp3" title="">
                                Az-Zumar
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plyr-wrp v2 overlap45 gray-layer overlap-45 w-100 audio-listen">
                    <div className="row mrg">
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <div
                          className="plyr-inner position-relative pat-bg white-layer opc9 back-blend-multiply bg-white"
                          style={{
                            backgroundImage: `url(assets/images/pattern-bg.jpg)`,
                          }}
                        >
                          <div className="plyr v2 w-100">
                            <ul className="playlist mb-0 list-unstyled">
                              <li
                                data-cover="assets/images/audio-cover4.jpg"
                                data-artist=""
                              >
                                <a href="assets/audio/al-mursalat.mp3" title="">
                                Al-Mursalat
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   <div className="plyr-wrp v2 overlap45 gray-layer overlap-45 w-100 audio-listen">
                    <div className="row mrg">
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <div
                          className="plyr-inner position-relative pat-bg white-layer opc9 back-blend-multiply bg-white"
                          style={{
                            backgroundImage: `url(assets/images/pattern-bg.jpg)`,
                          }}
                        >
                          <div className="plyr v2 w-100">
                            <ul className="playlist mb-0 list-unstyled">
                              <li
                                data-cover="assets/images/audio-cover5.jpg"
                                data-artist=""
                              >
                                <a href="assets/audio/an-naba.mp3" title="">
                                An-Naba
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   <div className="plyr-wrp v2 overlap45 gray-layer overlap-45 w-100 audio-listen">
                    <div className="row mrg">
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <div
                          className="plyr-inner position-relative pat-bg white-layer opc9 back-blend-multiply bg-white"
                          style={{
                            backgroundImage: `url(assets/images/pattern-bg.jpg)`,
                          }}
                        >
                          <div className="plyr v2 w-100">
                            <ul className="playlist mb-0 list-unstyled">
                              <li
                                data-cover="assets/images/audio-cover6.jpg"
                                data-artist=""
                              >
                                <a href="assets/audio/ghafir.mp3" title="">
                                Ghafir
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
