import axios from "axios";
const API_URL = process.env.REACT_APP_API_Link;

const namazTime = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(API_URL + "/user-namaz-timings", {
        headers: headers,
    });
};

const contactSubmit = (values) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.post(API_URL + "/contact", {
        "name": values.name,
        "email": values.email,
        "phone": values.phone,
        "message": values.message
    }, {
        headers: headers,
    });
};

const services = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(API_URL + "/services", {
        headers: headers,
    });
};

const teachers = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(API_URL + "/teachers", {
        headers: headers,
    });
};

const programs = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(API_URL + "/weekly-programs", {
        headers: headers,
    });
};

const enrollments = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(API_URL + "/enrollments", {
        headers: headers,
    });
};

const sliders = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(API_URL + "/sliders", {
        headers: headers,
    });
};

const sunTime = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(API_URL + "/sun-time", {
        headers: headers,
    });
};

const siteInfo = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(API_URL + "/site-info", {
        headers: headers,
    });
};

// const getDate = () => {
//     const options = {
//         method: 'GET',
//         url: 'https://calendar-converter.p.rapidapi.com/convert',
//         params: {
//           to: 'Islamic',
//           date: '2023-09-05'
//         },
//         headers: {
//           'X-RapidAPI-Key': 'babf9ca6efmshd0306882bdd87b9p189ec5jsn26d12508c582',
//           'X-RapidAPI-Host': 'calendar-converter.p.rapidapi.com'
//         }
//       };

//       const response = axios.request(options);
//       return response;
// };

    const getDate = (day, month, year) => {
        const headers = {
            "Content-Type": "application/json",
        };
        return axios.get(`https://api.aladhan.com/v1/gToH?
            date=${day}-${month}-${year}`, {
            headers: headers,
        });
    };

const UserService = {
    namazTime,
    teachers,
    services,
    programs,
    enrollments,
    sliders,
    sunTime,
    siteInfo,
    contactSubmit,
    getDate
};

export default UserService;
